import '../stylesheets/SummaryModules.scss';
import eventLogo from '../../assets/default/images/event-logo-volvo.png';
import { useEffect, useCallback, useState } from 'react';
import {getSummaryModules} from '../../utils/fetch/llmFetching';
import {summaryRangeStart, summaryRangeEnd} from "../../utils/constants";
import { useErrorBoundary } from "react-error-boundary";

const SummaryModules = ({ datestart, dateend }) => {
  const { showBoundary } = useErrorBoundary();

  const [modules, setModules] = useState(null);

  const getData = useCallback(async () => {
    try {
      const response = await getSummaryModules();

      setModules(response);
    } catch (e) {
      return null;
      showBoundary(e);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="summaries-container">
      <img src={eventLogo} alt="Volvo XC90" onClick={() => window.location = '/menu'} />
      <hr />
      <div className="summaries-scrollable">
        <h3>Session Summaries</h3>
        {modules && modules.map(module => <button key={module._id} type="button" onClick={() => window.location = `/session-summaries/${module._id}?datestart=${datestart}&dateend=${dateend}`}>{module.title}</button>)}
      </div>
    </div>
  );
};

export default SummaryModules;
