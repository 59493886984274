
exports.wakeWords = ["hey coach"];
exports.wakeWordDisplay = "Hey Coach";
exports.emptyTranscribeResponse = 'Sorry, could you repeat that?';

exports.stopRecordingDelay = 1500; // 1.5 seconds
exports.maxAudioRecording = 90000;

// Replacement words
exports.replaceList = [
   ["f-150", "F150"],
   ["EQS", "E QS"],
   ["Ioniq 6's", "Ioniq 6"],
   ["lb", "pound"],
   ["lb.", "pound"],
   ["lbs", "pounds"],
   ["lbs.", "pounds"],
   ["lb-ft", "pound feet"],
   ["lb.-ft.", "pound feet"],
   ["ft3", "feet cubed"],
   ["cu. ft.", "cubic feet"],
   ["ft", "feet"],
   ["mi", "miles"],
   ["hp", "horse power"],
   ["sec", "seconds"],
   ["sec.", "seconds"],
   ["min", "minutes"],
   ["min.", "minutes"],
   ["hrs", "hours"],
   ["mi", "miles"],
   ["awd", "all wheel drive"],
   ["rwd", "rear wheel drive"],
   ["vdc", "volts of direct current"],
   ["kg", "kilogram"],
   ["kgs", "kilograms"],
   ["yrs", "years"],
   ["mph", "miles per hour"],
   ["kph", "kilometers per hour"]
 ];

 // What we can't replace
//  ["in", "inches"] => would replace all instances of the word "in"

// Prompt Tester
exports.promptTesterModId = "664a8bb685f669fb97714e53";

// KB
exports.kbModList = {
   'tesla-cybertruck': '6642267adf09872383c6a7f0',
   'ford-f-150-lightning': '664a870e85f669fb97714dea',
   'ford-mustang-mach-e': '664a879585f669fb97714df5',
   'gmc-hummer-ev-suv-3x': '664a87de85f669fb97714dfe',
   'hyundai-ioniq-6': '664a88a485f669fb97714e07',
   'kia-ev6': '664a891685f669fb97714e10',
   'lucid-air': '664a894a85f669fb97714e17',
   'mercedes-benz-eqs-450-plus': '664a898285f669fb97714e1e',
   'polestar-polestar2': '664a89b885f669fb97714e25',
   'rivian-r1s': '664a89e485f669fb97714e2c',
   'volvo-xc90': '66b2667cbec33d2b8ee93ce5'
};

// Role Play
exports.rpModList = {
   'role-play': {
      id: '66b267af2e2a4e4cd947d10d', evalLimit: 50, avatarName: "Ava", 
      avatar: "https://openai-chatbot-public.s3.us-west-2.amazonaws.com/Avatar-Ava-3-0.png", voice: "en-US-AvaNeural", idle: "/ava.mp4", file: "ava",
      landing: "<p>Click start to begin your conversation with Ava and learn about her needs.</p><p>Begin by welcoming her...</p>"
   },
   'objection': {
      id: '664a8aad85f669fb97714e3d', evalLimit: 50 , avatarName: "Emma", 
      avatar: "https://openai-chatbot-public.s3.us-west-2.amazonaws.com/Avatar-Emma-1.0.png", voice: "en-US-EmmaNeural", idle: "/emma.mp4", file: "emma",
      landing: "<p>You and your Guest just wrapped up a test drive where you did a thorough presentation. Your Guest looks ready to move to the purchasing phase, but you ask her the following question to ensure she's ready.<br />Start the conversation by asking:</p><p>“Do you have any final questions or concerns?”</p>"
   },
   'objection-advance': {
      id: '664a8ade85f669fb97714e42', evalLimit: 50, avatarName: "Andrew", 
      avatar: "https://openai-chatbot-public.s3.us-west-2.amazonaws.com/Avatar-Andrew-1.0.png", voice: "en-US-AndrewMultilingualNeural", idle: "/andrew.mp4", file: "andrew",
      landing: "<p>You just wrapped up an extended test drive with a well-informed Guest. You successfully answered all their product questions, and they seem ready to purchase, but you ask the following question to ensure they are ready.<br />Start the conversation by asking:</p><p>“Do you have any final questions or concerns?”</p>"
   },   
};

// KB
exports.kbVechList = {
   'tesla-cybertruck': {brand: 'Tesla', model: 'Cybertruck'},
   'ford-f-150-lightning': {brand: 'Ford', model: 'F-150 Lightning'},
   'ford-mustang-mach-e': {brand: 'Ford', model: 'Mustang Mach-E'},
   'gmc-hummer-ev-suv-3x': {brand: 'GMC', model: 'Hummer EV SUV 3X'},
   'hyundai-ioniq-6': {brand: 'Hyundai', model: 'IONIQ 6'},
   'kia-ev6': {brand: 'Kia', model: 'EV6'},
   'lucid-air': {brand: 'Lucid', model: 'Air'},
   'mercedes-benz-eqs-450-plus': {brand: 'Mercedes', model: 'EQS 450+'},
   'polestar-polestar2': {brand: 'Polestar', model: 'Polestar 2'},
   'rivian-r1s': {brand: 'Rivian', model: 'R1S'},
   'volvo-xc90': {brand: 'Volvo', model: 'XC90 Recharge'}
};

// Landing pages
exports.needsLink = `/role-play`;
exports.objectionLink = `/objection`;
exports.advanceLink = `/objection-advance`;

// Session summary pages
exports.summaryRangeStart = '2024-07-15';
exports.summaryRangeEnd = '2024-07-31';