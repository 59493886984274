// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container .not-mobile {
  color: #888888; }

.header-container .mobile {
  color: white; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Header.scss"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAA;;AAGhB;EACE,YAAY,EAAA","sourcesContent":[".header-container .not-mobile {\n  color: #888888;\n}\n\n.header-container .mobile {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
