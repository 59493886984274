// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.evaluation-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%; }
  .evaluation-container > img {
    margin: 2rem auto; }
  .evaluation-container a {
    text-decoration: none; }
    .evaluation-container a img {
      padding-left: 10px; }
  .evaluation-container hr {
    border: 1px solid #4898D3;
    margin: 0; }
  .evaluation-container .evaluation-scrollable {
    flex: 1 1;
    margin: 0 auto;
    max-width: 700px;
    overflow-y: auto;
    padding: 1.5rem 1rem;
    width: 100%; }
    .evaluation-container .evaluation-scrollable h3 {
      font-size: 2rem;
      font-weight: 700;
      margin: 0 auto 1rem;
      max-width: 700px;
      width: 100%; }
    .evaluation-container .evaluation-scrollable h6 {
      color: #4898D3;
      font-weight: 700;
      font-size: 1.1rem; }
    .evaluation-container .evaluation-scrollable .evalDate {
      font-weight: 700;
      margin-bottom: 30px; }
    .evaluation-container .evaluation-scrollable .wmde-markdown {
      background-color: #FFF;
      color: #000; }
      .evaluation-container .evaluation-scrollable .wmde-markdown p > strong {
        color: #4898D3;
        font-weight: 700;
        font-size: 1.1rem; }

@media screen and (orientation: portrait) {
  .evaluation-container h3 {
    max-width: 600px; }
  .evaluation-container .evaluation-scrollable {
    max-width: 600px; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Evaluation.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,8BAA8B;EAC9B,WAAW,EAAA;EAJb;IAOI,iBAAiB,EAAA;EAPrB;IAWI,qBAAqB,EAAA;IAXzB;MAcM,kBAAkB,EAAA;EAdxB;IAmBI,yBAAyB;IACzB,SAAS,EAAA;EApBb;IAwBI,SAAO;IACP,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,WAAW,EAAA;IA7Bf;MAiCM,eAAe;MACf,gBAAgB;MAChB,mBAAmB;MACnB,gBAAgB;MAChB,WAAW,EAAA;IArCjB;MAyCM,cAAc;MACd,gBAAgB;MAChB,iBAAiB,EAAA;IA3CvB;MA+CM,gBAAgB;MAChB,mBAAmB,EAAA;IAhDzB;MAqDM,sBAAsB;MACtB,WAAW,EAAA;MAtDjB;QAyDQ,cAAc;QACd,gBAAgB;QAChB,iBAAiB,EAAA;;AAOzB;EACE;IAEI,gBAAgB,EAAA;EAFpB;IAMI,gBAAgB,EAAA,EACjB","sourcesContent":[".evaluation-container {\n  display: flex;\n  flex-flow: column;\n  justify-content: space-between;\n  width: 100%;\n\n  > img {\n    margin: 2rem auto;\n  }\n\n  a {\n    text-decoration: none;\n\n    img {\n      padding-left: 10px;\n    }\n  }\n\n  hr {\n    border: 1px solid #4898D3;\n    margin: 0;\n  }\n\n  .evaluation-scrollable {\n    flex: 1;\n    margin: 0 auto;\n    max-width: 700px;\n    overflow-y: auto;\n    padding: 1.5rem 1rem;\n    width: 100%;\n    //white-space: pre-wrap;\n\n    h3 {\n      font-size: 2rem;\n      font-weight: 700;\n      margin: 0 auto 1rem;\n      max-width: 700px;\n      width: 100%;\n    }\n  \n    h6 {\n      color: #4898D3;\n      font-weight: 700;\n      font-size: 1.1rem;\n    }\n\n    .evalDate {\n      font-weight: 700;\n      margin-bottom: 30px;\n    }\n\n    // markdown override\n    .wmde-markdown {\n      background-color: #FFF;\n      color: #000;\n\n      p > strong {\n        color: #4898D3;\n        font-weight: 700;\n        font-size: 1.1rem;\n      }\n\n    }\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .evaluation-container {\n    h3 {\n      max-width: 600px;\n    }\n\n    .evaluation-scrollable {\n      max-width: 600px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
